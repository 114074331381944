



.works-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 900px;

  justify-content: space-between;

}


.mma{
  background-image: url('./images/mma.jpg');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;

}

.was {
  background: url('./images/w-a-s.jpg');
  background-size: 130%;
  background-position: center;
}

.rug {
  background-image: url('./images/rugisthedrug.jpg');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: white;
}

.drum {
  background: url('./images/DrumDrum.PNG');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.travel {
  background: url('./images/travel.jpg');
  background-size: 180%;
  background-position: center;
}

.abc {
  background: url('./images/abc.jpg');
  background-size: 160%;
  background-position: center;
}

.ja {
  background: url('./images/jarrolds.jpg');
  background-size: 120%;
  background-position: center;
}

.rm {
  background: url('./images/viking.jpg');
  background-size: 150%;
  background-position: center;
}

.wm {
  background: url('./images/tractor.jpg');
  background-size: 140%;
  background-position: center;
  background-repeat: no-repeat;
}

.wig {
  background: url('./images/wigs.jpg');
  background-size: 200%;
  background-position: 10% 80%;
  background-color: white;
  background-repeat: no-repeat;
}

.work {

  width: 300px;
  height: 300px;


}

.work-title {
  color: white;
  font-size: 2.3em;
  filter: blur(4px);
  letter-spacing: 10px;
  transition: 0.3s;
}
.overlay {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: 0.3s;
}

.overlay:hover {
  background: rgba(0,0,0,0.8);
}

.overlay:hover .work-title {
  filter: blur(0px);
  letter-spacing: 10px;

}
.divider-svg {
  width: 80px;
  position: absolute;
  right: 22.5%;
  fill: var(--hl1);
  bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .works-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }


}
