@font-face {
  font-family: 'displaygx';
  src: local('sign'), url(./fonts/displaygx.ttf) format('truetype');
}


@font-face {
  font-family: 'mustica';
  src: local('sign'), url(./fonts/mustica.otf) format('opentype');
}

h2 {
  font-family: 'mustica';
}


.site-content {
  display:flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  min-height: 300vh;

}

@keyframes site-content-fade {
  0% {
    background: white;
  }
  50% {
    background: white;
  }
  100% {
    background: var(--dark);
  }
}
:root {
  --light: white;
  --dark: black;
  --hl1: #F37A53;
  --hl2: #4ABBA0;
  --hl3: #CA64BF;
}

body {
background: var(--light);
overflow-x: hidden;
background: var(--dark);
}

.progress {
  fill: red;
  position: fixed;
  top: 50px;
  left: 50px;
  width: 20%;
  height: 20%;
  border: 2px solid black;
}
span {
  font-family: 'displaygx';
}

h1 {
font-family: 'mustica';
  margin-top: 0px;

  color: var(--light);


}

p {
  color: white;
}

h2 {
  font-family: 'mustica';
  color: var(--dark);
  font-size: 2.5em;
}

p {
  font-family: 'displaygx';
}

h1 span {
  font-family: 'mustica';
}

.hl2 {
  color: var(--hl2);
}


.page-title {
  font-size: 3.5em;
  text-align: center;
  letter-spacing: 3px;
  margin-bottom: 5px;
}

.page {
  padding-top: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.section {
  width: 82%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.section-header {
  text-align: center;
  width: 100%;

}

#root {
  overflow-x: hidden;
  position: relative;
}
@media only screen and (max-width: 600px) {
  .section {
    width: 100%;
    margin-top: 30px;
    display: flex;
  }

  p {
    width: 90%;
    margin: auto;
  }


}

.hl1 {
  color: var(--hl1);
}

.hl2 {
  color: var(--hl2);
}

.hl3 {
  color: var(--hl3);
}
