.landing {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;

  background-repeat: no-repeat;
  background-position: center;



}

.circle-outline {
  width: 250px;
  position: absolute;
  right: 13vw;
  bottom: 4vh;
  fill: var(--dark);
}
.circle-filled {
  width: 250px;
  left: 70vw;
  top: 20vh;
  fill: #30B5BC;
  position: absolute;
  z-index: 9;
    animation: 2s dot-load 1;
}
.dots-landing {
  position: absolute;
  width: 300px;
  fill:var(--dark);
  left: 10vw;;
  top: 1vh;
    animation: 2s dot-load 1;
}

.dots-landing rect {
  position: relative;

}





@keyframes reverse-underline {
  0% {
      left: 100%;
      width: 0%;
  }
  50% {
    left: 100%;
    width: 0%;
  }
  100% {
    left: 0%;
    width: 100%;
  }
}

.landing-inner {
  width: 100%;
  height: 100%;
  display: flex;
    height: 90vh;

  align-items: center;
  justify-content: center;
  padding-top: 1em;
  padding-bottom: 3em;
  flex-direction: column;

  background-position: center;

  background-size: 100%;
  overflow: hidden;

}



@keyframes backgorund-ani {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.button {

  border: 3px solid white;
  background: black;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 15px;

  letter-spacing: 5px;
  z-index: 99;
  border-radius: 1px;
  margin-top: 0px;
  overflow: hidden;
  position: relative;

}

@keyframes slide-from-right {
  0% {
    right: -80%;
  }
  60% {
    right: -80%;
    opacity: 0;
  }
  90% {
    right: 22%;
    opacity: 1;
  }
  100% {
    right: 20%;
    opacity: 1;
  }
}

.line3 {


}
.line2 {
  width: 100%;

  display: block;
}
.line1, .line2, .line3 {
  width: 100%;
  display: block;
  margin: 0px;
  position: relative;
}


.line3 {
  color: var(--hl3);

}

.line3:after {
  width: 75%;
  background: var(--hl3);
  background-size: 100%;
  height: 4px;
  content: "";
  position: absolute;
  left: 0px;
  animation: 2s underline-dev 1;
  bottom: -10px;
}

@keyframes underline-dev {
  0% {
    width: 0%;
    left: 36%;
  }
  70% {
    width: 0%;
    left: 36%;
  }
  100% {
    width: 75%;
  }
}

@keyframes bg-ani {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}







.button span {
  font-size: 1em;
  text-transform: uppercase;
  color: white;
  text-shadow: 0px 0px 1px white;
}
.landing-title {
  font-size: 5em;

  position: relative;
  letter-spacing: 4px;
  color: var(--light);
  line-height: 1.1em;
  text-align: left;
  animation-delay: 0s;
  position: relative;
  display: flex;
  flex-direction: column;

}

.eff {
  animation: 1s landing-ani infinite;
}


@keyframes spacing {
  0% {
    letter-spacing: 4px;
  }
  100% {
    letter-spacing: 10px;
  }
}

.clean {

}

.full {



}

.landing-block {
  width: 100px;
  height: 50px;
  background:var(--dark);
}

.highlight {
  position: relative;
}


.landing-row {
  width: 100%;
  margin-top: 0px;
  display: flex;
  height: 42.7vh;
  justify-content: center;
  overflow: hidden;
}
.col {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.l-col {
  position: relative;
  width: 33.33333%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.col p {
  width: 90%;
  font-size: 1.1em;
  text-align: center;
}


.l-col:before {
  width: 3px;
  background: var(--dark);
  height: 100%;
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  animation: 1.8s down-line 1;
}
.l-col:after {
  width: 3px;
  background: var(--dark);
  height: 100%;
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  animation: 1.8s down-line 1;
}

@keyframes down-line {
  0% {
    height: 0%;
  }
  80% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}


.shown {
  display: block;
  transition: 0.2s;
  position: relative;
  top: 0px;
    transform: rotate(0deg);
}

.button:hover .shown {
  top: -35px;
  filter: blur(10px);

}

.button:hover .shown:nth-child(2n) {
  top: 35px;
  filter: blur(10px);



}

.button {
  transition: 0.3s;

}
.button:hover {

}

.button:hover .hidden {
  left: 0px;
  transition-delay: 0.2s;
  filter: blur(0px);
    transform: skewx(0deg);

}

.button-effect {
  width: 250px;
  height: 250px;
  position: absolute;
  border-radius: 100%;
  background: rgba(255,255,255,0.3);
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.shown {
  width: 14px;
  pointer-events: none;
}

.hidden {
  pointer-events: none;
}

.shown:nth-child(1) {
transition-delay: 0.03s;
}

.shown:nth-child(2) {
transition-delay: 0.06s;
}

.shown:nth-child(3) {

transition-delay: 0.09s;
}

.shown:nth-child(4) {
transition-delay: 0.12s;
}

.shown:nth-child(5) {

transition-delay: 0.15s;
}

.shown:nth-child(6) {
transition-delay: 0.18s;
}
.shown:nth-child(7) {
transition-delay: 0.21s;
}
.shown:nth-child(8) {
transition-delay: 0.24s;
}
.shown:nth-child(9) {
transition-delay: 0.27s;
}

.shown:nth-child(10) {
transition-delay: 0.30s;
}

.shown:nth-child(11) {
transition-delay: 0.33s;
}

.shown:nth-child(12) {
transition-delay: 0.36s;
}

.hidden {
  position: absolute;
  left: -100%;
  width: 100%;
  transition: 0.2s;
  filter: blur(10px);
  transform: skewx(-10deg);

}


.intro-box {
  min-width: 800px;
  width: 60%;
  height: 65%;

  z-index: 99;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  border-radius: 10px 10px 0px 10px;
  margin-top: -50px;
  border: 4px solid var(--dark);

  background-size: 100%;
  background-position: 20% 60%;
  overflow: hidden;
    background-attachment: fixed;
}

.square {
  width: 300px;
  top: 0px;
  opacity: 0.5;
  fill: #bb91e4;
  position: absolute;
  animation: 1.3s fade-in 1, 2.8s rotate infinite linear, 2s scale-dip infinite;
}

.space-ship {
  position: absolute;
  width: 50px;
  top: 20px;
  left: 0px;
  transform: rotate(-44deg);
  animation: 3s spaceship infinite;
  fill: var(--dark);
}

@keyframes spaceship{
  0% {
    left: 20%;
    top: -10%;
  }
  50% {
    left: 20%;
    top: -10%;
  }
  100% {
    left: 20%;
    top: 10%;
  }
}

.thruster {
    fill: orange;
    position: relative;
    animation: 0.2s thruster-flick infinite;
    overflow: hidden;
    left: 0px;
}

@keyframes thruster-flick {
  0% {
    transform: scale(1);
    top: 0px;
    left: 0px;
  }
  50% {
    transform: scale(0.8);
    left: 6px;
    top: 10px;
  }
  100% {
    transform: scale(1);
    top: 0px;
    left: 0px;
  }
}




@keyframes fade-in {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
}
@keyframes scale-dip {
  0% {
    width: 520px;
  }
  50% {
    width: 530px;
  }
  100% {
    width: 520px;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.intro-box .top {
  height: 40px;
  background: var(--dark);
  width :100%;
}

.full {

}

.clean {
  font-size: 1em;
}

.highlight {


  position: relative;
}


.intro-box.back {
  background: var(--dark);
  position: absolute;
  left: 18.6vw;
  width: 62.5%;
  top: 16.6vh;
  height: 67%;
    z-index: 8;
}

.would {
  position: absolute;
  bottom: 10%;
  right: -25%;

  background: var(--dark);

  padding: 5px;
  padding-top: 15px;
  padding: 15px;
  text-transform: uppercase;
  font-family: 'mustica';
  border-radius: none;
  color: white;
  border: none;

  letter-spacing: 3px;
}

.small-svg {
  width: 100px;
  left: 0px;
  top: -30px;
  fill: var(--dark);
  transform: rotate(90deg);
  position: absolute;
}

.spiral {
  position: absolute;
  width: 155px;
  fill: var(--dark);
  right: 150px;
  bottom: -10px;
  transform:rotate(-100deg);
}

.landing-svg {
  position: absolute;
  width: 150px;
  left: 10vw;
  bottom: 150px;
}

.hidden {
  z-index: 9;
}


.button-effect {
  width: 0px;
  height: 0px;
  transition: width 1s, height 1s;
  opacity: 0;
  background: var(--hl1);
  z-index: 0;
}
.clicked .button-effect {
  animation: 0.45s pulse 1 linear;
}

@keyframes pulse{
  0% {
    opacity: 0;
    width: 0px;
    height: 0px;
  }
  30% {
      opacity: 1;
  }

  100% {
    width: 600px;
    height: 600px;
    opacity: 0;
  }
}

.down-arrow3 {
  width: 64px;
  height: 64px;
  margin-top: 30px;
  background: none;
  border: none;
  outline: none;
  display: none;
  border: 2px solid white;
  transform: rotate(-45deg);
}

.down-arrow svg {
}

@media only screen and (max-width: 600px) {
  .landing-title {
    font-size: 3em;
    text-align: center;
  }

  .landing-inner {
    height: 100vh;
  }

  .line3:after {
    background: none;
  }

  .down-arrow3 {

    display: block;

  }
}
