.header {
  display:flex;
  height: 80px;
  width: 100%;
  align-items: center;
  position: relative;
  margin: auto;
  justify-content: space-between;
  position: fixed;
  z-index: 199;
  background: rgba(0,0,0,0);
  transition: 0.2s;
  top: 0px;
  left: 0px;


}

a {
  text-decoration: none;
}


@keyframes header-underline {
  0% {
    width: 0%;
  }
  40% {
  width: 0%;

}
100% {
  width: 100%;
}
}


.logo-img {
  width: 30px;
  fill: var(--dark);
  transition: 0.3s;


}

.logo:hover .logo-small {
  fill: var(--hl2);
}

.logo:hover .logo-text {
  letter-spacing: 3px;
}
.logo-small {
  width: 32px;
  transition: 0.9s;
  fill: white;

}

.offTop .logo-small {
  fill: white;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'mustica';
}

.logo-text {
  font-family: 'mustica';
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
}

.logo-img:hover {
  transform: scale(1.15);
}

.header .logo-img {
  fill: var(--dark);



}

.logo {
  width: max-content;
  padding: 4px;

}

.menu-button {


  border: none;
  outline: none;
  position: relative;
  padding: 7px;
  background: none;
  color: var(--dark);
  z-index: 90;
  animation: 1.8s menu-button-load 1;
}


.menu-button span {
  position: relative;
  top: 0px;
  color: rgba(255,255,255,0.5);
  transition: 0.15s;
}

.menu-button:after {
  width: 0%;
  position: absolute;
  bottom: 8px;
  height: 2px;
  background: black;
  content: "";
  left: 0px;
  transition: 0.1s;
}

.menu-button:hover:after {
  width: 100%;
  transition-delay: 0.45s;
}

.menu-button:hover span {
  top: -3px;
  color: var(--hl1) !important;
}

.menu-button span:nth-child(1) {
  transition-delay: 0.05s;
}
.menu-button span:nth-child(2) {
   transition-delay: 0.10s;
}
.menu-button span:nth-child(3) {
  transition-delay: 0.15s;
}
.menu-button span:nth-child(4) {
  transition-delay: 0.2s;
}
.menu-button span:nth-child(5) {
  transition-delay: 0.25s;
}
.menu-button span:nth-child(6) {
  transition-delay: 0.3s;
}

.menu-button span:nth-child(7) {
  transition-delay: 0.35s;
}

.menu-button span:nth-child(8) {
  transition-delay: 0.4s;
}
.menu-button:hover {

}

.menu-button.contact {



}

.menu {
  display:flex;
  align-items: center;
  justify-content: space-around;
  width: 50%;
}

.menu-button:nth-child(1) {
  animation-delay: -1.2s;
}

.menu-button:nth-child(2n) {
  animation-delay: -1.1s;
}

.menu-button:nth-child(3) {
  animation-delay: -0.9s;
}

.menu-button:nth-child(4) {
  animation-delay: -0.8s;
}

.menu-button:nth-child(5) {
  animation-delay: -0.7s;
}


@keyframes menu-button-load {
  0% {
    opacity: 0;
    top: -100px;
  }
  80% {
    opacity: 0;
    top: -100px;
    transform: rotate(0deg);
  }
  92% {
    opacity: 0.8;
    top: 10px;

  }
  100% {
    opacity: 1;
    top: 0px;
    transform: rotate(0deg);

  }
}


.header.offTop {
  background: black;
  height: 60px;
  z-index: 200;
}

.offTop .menu-button span {
  color: white;
}

.logo-text {
  transition: 1s;
}

.logo-text {

  letter-spacing: 2px;
  color: white;
}

.header:after {
  width: 0%;
  background: var(--hl2);
  content: "";
  bottom: 0px;
  height: 2px;
  position: absolute;
  transition: 0.3s;
  box-shadow: 3px 0px 5px #E27981;
}

.offTop:after {
  width: 100%;
  transition-delay: 0.3s;

}

.hamburger {

  z-index: 101;
  right: 30px;
  position: relative;
  visibility: hidden;
  display: flex;
  width: 32px;
  height: 32px;
  background: none;
  border: none;
  justify-content: space-between;
  flex-direction: column;

}

.hamburger span {
  width: 100%;
  height: 5px;
  margin-bottom: 5px;
  background: white;
  position: relative;
  border-radius: 5px;
  transition: 0.3s;
  top: 0px;

}

@media only screen and (max-width: 600px) {
  .menu {
    top: 0px;
    position: absolute;
    flex-direction: column;
    left: 100%;
    font-size: 3em;
    background: black;
    width: 100%;
    transition: 0.3s;
    justify-content: center;
    height: 100vh;
    z-index: 99;

  }

  .hamburger {
        visibility: visible;

  }

  .hamburger.active span {
    background: white !important;

  }
  .hamburger.active span:nth-child(1) {
    top: 10px;
    transform: rotate(225deg);
  }

  .hamburger.active span:nth-child(2) {
    transform: rotate(-225deg);
  }

  .hamburger.active span:nth-child(3) {
    top: -10px;
    transform: rotate(225deg);

  }

  .menu.active .menu-button .button-inner span {
    color: white !important;
  }

  .menu.active{
    left: 0%;
    transition-delay: 0.3s;
  }

  .menu-button {
    font-size: 1em;
    margin: 0px;
  }

  .menu-back {
    width: 100%;
    height: 100%;
    background: black;
    position: absolute;
    top: 0px;
    left: -200%;
    transition: 0.3s;
  }

  .active .menu-back {
    left: 0%;
    background: var(--hl2);
  }
}
