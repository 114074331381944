.service-header {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
    width: 100%;
  letter-spacing: 3px;
}

.service-intro {
  width: 50%;


}

.service-intro p{


  text-align: center;
}


.service-intro h1 {
    text-align: center;
    font-size: 2.1em;
}

.services {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;

}



.step-start {
  color: var(--hl1);
}


.service-card {
  width: 200px;
  height: 250px;
  border: 2px solid white;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.service-card-top {
  position: absolute;
  width: 90px;
  height: 90px;
  background: white;
  border-radius: 100%;
  left: -50px;
  top: -50px;
}


.step-title {
  color: white;

left: -100px !important;
  width: 150px;
  position: absolute;
  transition: 0.2s;




}
.step-header {
  position: relative;
  width: 100%;
}
.step-count-holder {
  position: absolute;
  border: 2px solid white;
  top: -25px;
  left: -30px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10%;
  overflow: hidden;
  transition: 0.5s;

}

.step-visual {
  width: 80px;
  fill: white;
  position: absolute;
  left: 20%;
  top: 60%;
  opacity: 1;
}

.step-count-holder.pos1 {

}

.step-count-holder.pos2 {
  left: 30%;
}

.step-count-holder.pos3 {
  left: 90%;
}

.step-count {
  position: absolute;
  font-size: 1.2em;
  right: -100px;
  color: white;
  transition: 0.3s;
  font-family: 'mustica';
}

.step-count.active {
  top: -5%;
  right: 30%;
}


.step-title.change {
  width: 180px;
}

.step-part {
  position: absolute;
  top: 11%;
  transition: 0.3s;

  transition-delay: 0s;

  max-width: 0px;
  filter:blur(5px);
  transform: scaley(0);
}

.step-part.active {
  max-width: 500px;
  opacity: 1;
  filter:blur(0px);
    transform: scaley(1);
  transition-delay: 0.2s;
}

.circle1 {
  fill: white;

  transition: 0.3s;
}

.circle1.active {
  fill: var(--hl2);
  opacity: 1;
  transition-delay: 0.3s;

}

.arrow.active  {
  animation: 0.5s arrow-flash 1;
}

@keyframes arrow-flash {
  0% {
    fill: white;
  }
  15% {
    fill: var(--hl2);
  }
  100% {
    fill: white;
  }
}

.bar {
  transition: 0.5s;
}
.bar.active {
  fill: var(white);


}

.step-part.special {
  left: -14px;
  top: 5px;
}

.step-title.special {
animation:  0.3s text-flip 1;
left: 30px;
}

.step-card {
  width: 500px;
  height: 400px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

}

.card-svg {
  width: 160px;
}
.graphic-holder {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  transition: 0.3s;

}

.graphic-holder {
  left: -300px;
  transition: 0.2s;
  opacity: 0;
}

.graphic-holder.active {
  left: 0px !important;
  opacity: 1;
}



.card-svg.flipped {
  transform: scale(-1);
  opacity: 0.6;
  filter: blur(4px);
  margin-top: 10px;
}


@keyframes text-flip{
  10% {
    left: 200px;
  }
  100% {
    left: 30px;
  }
}

.card-text {
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 200px;
  overflow: hidden;
  text-align: center;

}

.card-text-inner {
  position: absolute;
  top: 1000px;
  display: block;
  filter: blur(10px);
  transition: 0.32s;
}

.card-text-inner.active {
  top: 0px;
  filter: blur(0px);

}

.step-row {
  display: flex;
  position: relative;
  justify-content: space-between;
}

.progress-indicator {
  height: 300px;
  width: 64px;
  border-right: 2px solid white;
  position: absolute;
  left: -100px;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
}

.progress1 {
  width: 20px;
  border-radius: 100%;
  margin-bottom: 10px;
  height: 20px;
  background: rgba(0,0,0,0);
  border: 3px solid white;
}

.progress1.active {
  background: white;
  width: 26px;
  height: 26px;
}

.arrow-row {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: auto;
}

.arrow-button {
  background: url("./images/arrow.png");
  background-size: 90%;
  width: 42px;
  height: 42px;
  border: none;
  background-position: center;
  transition: 0.2s;
  opacity: 0.9;
  background-repeat: no-repeat;
}

.arrow-button:hover {
  background-size: 100%;
  opacity: 1;
}

.arrow-button.backward {
  transform: scale(-1);
}

.service-graphic {
  width: 400px;
}

.stack {
  color: white;
  display:block;
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
}
.service-text {
  width: 60%;
  margin-right: 5%;
}

.graphic-header {
  width: 70%;
  display:flex;
  justify-content: space-between;
}

.service-row {
  display: flex;
  justify-content: space-between;
  margin-top: 100px;
  margin-bottom: 50px;
  flex-direction: column;
  position: relative;

}

.service-arrow {
  width: 200px;
  position: absolute;

}
.service-text {
  color: white;
}
.service-arrow.p1 {
  left: 100px;
  top: 80px;
  transform: rotate(15deg);
}

.service-text h3 {
  text-align: left;
  letter-spacing: 3px;
}

.service-arrow.p2 {
  left: -100px;
  top: 260px;
  transform: rotate(-15deg) scalex(-1);
}
.service {
  height: 180px;
  width: 220px;

  border-radius:10px;
  position: relative;
}

.card-svg.small {
  width: 80px;
}

.develop .card-svg.small {
  width: 75px;
  margin-top: 10px;
}

.service-label {
  font-size: 2em;
  position: absolute;
  bottom: 40px;
  width: 100%;
  letter-spacing: 3px;
  text-align: center;
}

.servicesvg {
  width: 120px;
  position: absolute;
  left: calc(0% - 60px);
  fill: var(--hl3);
  top: -50px;
}

.section-header h1 {
  position: relative;
  z-index: 100;
}
.section-header {
  position: relative;
}

@media only screen and (max-width: 600px) {
.service-row {
  width: max-content;

  bottom: -50px;
  display: flex;

  align-items: center;


}

.servicesvg {
  left: calc(30% - 60px);
  top: 40px;
  width: 60px;
}


.service-text {
  width:100%;
  background: rgba(0,0,0, 0.8);
  z-index: 99;
  margin: auto;
  text-align: center;
}
.section  {
  display: flex;
  position: relative;
  flex-direction: column;
}

.row {
  flex-direction: column;
}

.service-text h3 {
  text-align: center;
}


.service-text h3{
  text-shadow: 1px 2px black;

}

.service-row {
  flex-direction: row;
  width: 100%;

}

.service-label {
  font-size: 1.5em;
}

.service-arrow.p1 {
  width: 150px;
  left: 0px;
  top: 50%;
  transform: scalex(-1) rotate(90deg);
  left: 15%;
}

.service-arrow.p2 {
  width: 150px;
  left: 51%;
  top: 50%;
  transform: scalex(-1) rotate(90deg);

}

.service-row {
  margin-top: 0px;

}



}
