
.intro{

  width: 100%;

  height: 500px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;


}

.intro-header h1 {
  margin: 0px;
  font-size: 2.1em;
  text-shadow: 4px 0px black;
  text-shadow: 0px 3px black;
  text-shadow: 0px -3px black;

}

.intro-header h1:nth-child(1) {

}

.intro-header h1:nth-child(2) {

}



.together {
  margin-left: 8px;
}

.intro-text {
  width: 50%;
}



.down-arrow {
  width: 150px;
  fill: #FDD06A;
}

.intro-svg {
  width: 240px;
  fill: white;
}


@keyframes background-ani {
  0% {

  }
  100% {

  }
}

.intro-ani {
  width: 400px;
  height: 200px;
  background: url('./images/loops.gif');
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: center;
}

.svg-backdrop {
  position: absolute;
  width: 400px;
  opacity: 0.5;
  left: -50px;
  fill:var(--hl3);
}


.body {
  width: 46px;
}

.hair {
  width: 20px;
  position: absolute;
  left: 14px;
  top: -8px;
}

.link-container {
  display: flex;
  color: white;
  align-items: center;

}
.man-container {
  position: relative;

  width: max-content;
}

.tech-head {
  color: white;
  display: flex;
}

.tech-row {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.tech {
  border: 2px solid white;
  padding: 3px;
  display: block;
  color: white;
  margin: 5px;
}

.five:hover {
  color: var(--hl2);
}

.score-holder {
  display: flex;
}

.score {
  display: block;
  width: 100px;
  text-align: left;
  color: white;
}

@media only screen and (max-width: 600px) {
  .intro {
    width: 100%;
    text-align: center;
    flex-direction: column-reverse;
    height: max-content;

  }
  .intro-header {
    margin-bottom: 30px;
  }
  .intro-text {
    width: 100%;
    position: relative;
    z-index: 9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.8);
  }



  .intro-text p {
    width: 90%;
    margin-bottom: 15px;
  }

  .tech-wrap {
    display: none;
  }

  .intro-ani {
    width: 400px;
    position: absolute;
    height: 200px;
    top: 0px;
    background: url('./images/loops.gif');
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: center;
  }

  .intro {

  }

  .hidden-on-mobile {
    display: none;
  }


}
