.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background:black;
  position: fixed;
  left: 0px;
  top: 0px;
  flex-direction: column;
  z-index: 200;
}

.loader-title {
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  color: white;
  letter-spacing: 4px;
  font-size: 3em;
}

.loader-logo {
  width: max-content;
  height: max-content;
}
.loader-logo svg {
  width: 32px;
  fill: white;
}

.loader-title span {
  display: block;




}

.words-container {

  overflow: hidden;
  width: 50%;
  height: max-content;
  display: flex;
  position: relative;
  letter-spacing: 4px;
  align-items: center;
  padding-bottom: 30px;
  justify-content: center;
}

.loader-bar {
  height: 6px;
  margin-top: 20px;
  background: white;
  border-radius: 0px;
  position: relative;
  animation: 0.2s loader-ani 10 alternate;
}

@keyframes loader-ani {
  0% {
    opacity: 0.75;

  }
  50% {
    opacity: 1;

  }
  100% {
    opacity: 0;
  }
}

.loader .logo-img {
  position: relative;
  animation: 1s logo-img-ani 2;
}
