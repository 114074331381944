.quote-holder {
  color: white;
}


.quote-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  width: max-content;
  margin: auto;
  width: 100%;

}


.quote {
  font-style: italic;
}

.author {

  width: 80%;
  text-align: right;
  font-size: 1.5em;
}

.quote h3 {
  position: relative;
  font-size: 1.85em;
  margin-bottom: 0px;
}
.quote h3:before {
  background: url('./images/quote.png');
  background-size: 100%;
  content: "";
  position: absolute;
  width: 28px;
  height: 28px;
  left: -32px;
    top: -10px;
  transform: scalex(-1);

}

.quote h3:after {
  background: url('./images/quote.png');
  background-size: 100%;
  content: "";
  position: absolute;
  width: 28px;
  top: -10px;
  right: -32px;
  height: 28px;


}
.author h4:before {
  content: "- ";
}

@media only screen and (max-width: 600px) {
  .quote-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: auto;
    font-size: .8em;
    margin-top: 30px;
    display: none;

  }
  .author h4 {
    margin: none;
  }

  .author {
    position: relative;
    top: -25px;
    right: -50px;
  }
}
